.nostra__summary__market-borrow {
  display: flex;

  .nostra__summary-box__main-value {
    flex: 1;

    .nostra__loading-placeholder {
      margin-top: 8px;
    }
  }

  .nostra__summary__market-borrow-side-values {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
}
