.nostra__wide-message {
  display: flex;
  align-items: center;
  background-color: var(--userWarningBackground);
  padding: 8px;
  border-radius: 0 0 4px 4px;

  strong {
    font-weight: 700;
  }

  > .nostra__icon {
    margin-right: 8px;
    flex-shrink: 0;
  }

  .nostra__link {
    text-decoration: underline;
  }
}
