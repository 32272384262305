.nostra__action-modal__success-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 20px;
  padding: 0px 20px;
  margin-bottom: 8px;
  box-sizing: border-box;

  .nostra__action-modal__success-row-label {
    display: flex;
    align-items: center;

    svg {
      margin-right: 8px;
    }
  }

  .nostra__action-modal__success-row-value {
    display: flex;
    align-items: center;

    .nostra__icon-right-arrow-thin {
      margin: 0 8px;
    }
  }
}

.nostra__action-modal__success-info {
  height: 72px;
  border: 1px solid var(--buttonBorderSuccess);
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 0px 12px;
  box-sizing: border-box;
  margin-top: 24px;
  background: var(--actionModalSuccessContainer);

  svg {
    margin-right: 8px;
  }

  .nostra__action-modal__success-info-text {
    flex: 1;
  }

  .nostra__action-modal__add-token-button {
    border: 1px solid var(--buttonBorder);
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
    box-sizing: border-box;
    background-color: var(--modalBackground);
  }
}

.nostra__action-modal__close-button {
  border: 1px solid var(--buttonBorder);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  width: 100%;
  box-sizing: border-box;
  margin-top: 24px;
  background-color: var(--modalBackground);
}

.nostra__action-modal__chain-view {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
  text-decoration: underline;
}
