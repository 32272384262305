.nostra__accountCard {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 1px solid var(--accountCardBorder);
  border-radius: 4px;
  box-shadow: var(--downWeakShadow);
}

.nostra__accountCard__health-factor-warning {
  width: 100%;
  height: 28px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border-radius: 3px 3px 0 0;

  &.nostra__accountCard__low-health-factor-warning {
    background-color: var(--accountCardLowHealthFactorWarningBackground);
  }

  &.nostra__accountCard__medium-health-factor-warning {
    background-color: var(--accountCardMediumHealthFactorWarningBackground);
  }

  .nostra__icon {
    margin-right: 4px;
  }

  div:last-child {
    margin-left: 4px;
  }
}

.nostra__accountCard__content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 54px;
  padding: 0px 16px;
  box-sizing: border-box;

  .nostra__accountCard-label {
    display: flex;
    align-items: center;
  }

  .nostra__accountCard-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    div:first-child {
      margin-bottom: 4px;
    }

    .nostra__typography {
      max-width: 120px;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .nostra__accountCard-data-container {
    display: flex;

    .nostra__accountCard-data {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-left: 32px;

      .nostra__accountCard-data-label {
        margin-bottom: 4px;
        display: flex;
        justify-content: flex-end;
      }

      .nostra__accountCard-data-value {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}
