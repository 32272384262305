.nostra__analyticsTokenStats {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: var(--analyticsSectionBackground);
  box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
  box-sizing: border-box;
  border-radius: 4px;
  padding: 32px;

  .nostra__analyticsTokenStats__data {
    .nostra__analyticsTokenStats__dataItem {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 40px;

      &:not(:last-of-type) {
        border-bottom: 1px dashed #8998b7;
      }
    }
  }

  .nostra__analyticsTokenStats__title {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    .nostra__logo {
      margin-right: 12px;
    }
  }

  .nostra__analyticsTokenStats__chart {
    display: flex;

    .nostra__analyticsTokenStats__chartData {
      display: flex;
      flex-direction: column;

      .nostra__analyticsTokenStats__chartDataValues {
        display: flex;

        .nostra__typography {
          margin-bottom: 12px;
          white-space: nowrap;
        }

        .nostra__analyticsTokenStats__chartDataValuesLabel {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
        }

        .nostra__analyticsTokenStats__chartDataValue {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: flex-end;
          margin-left: 24px;

          width: 50px;

          .nostra__typography:first-child {
            margin-bottom: 12px;
          }
        }
      }
    }

    .nostra__analyticsTokenStats__chartContainer {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 420px;
      height: 120px;
      margin-left: 20px;

      .recharts-wrapper {
        pointer-events: none;
      }

      .nostra__analyticsTokenStats__chartLabels {
        display: flex;
        justify-content: space-between;
        margin-top: 6px;
        pointer-events: none;
      }

      .nostra__analyticsTokenStats__axisLabel {
        display: flex;
        justify-content: center;
        margin-top: 6px;
        pointer-events: none;
      }

      .nostra__analyticsTokenStats__referenceLine {
        position: absolute;
        bottom: 52px;
        transform: translateX(-50%);
        display: flex;
        flex-direction: column;
        align-items: center;

        .nostra__analyticsTokenStats__dottedLine {
          border: none;
          border-left: 1px dashed #8998b7;
          width: 1px;
          height: 38px;
        }
      }

      .nostra__analyticsTokenStats__chartContainer__event {
        position: absolute;
        width: 100%;
        height: 100%;
      }
    }
  }
}
