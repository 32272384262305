.nostra__walletPopup {
  position: fixed;
  bottom: 0px;
  right: -360px;
  top: 0px;
  width: 360px;
  background-color: white;
  box-shadow: 0px 20px 25px rgba(31, 41, 55, 0.1), 0px 10px 10px rgba(31, 41, 55, 0.04);
  box-sizing: border-box;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  padding: 24px;
  overflow-y: auto;
  transition: right ease-in-out 0.3s;

  &.nostra__walletPopup__open {
    right: 0px;
  }

  .nostra__walletPopup__info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;

    .nostra__walletPopup__info-title {
      display: flex;
      align-items: center;

      svg {
        margin-right: 16px;
      }
    }
  }

  .nostra__walletPopup__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  .nostra__walletPopup__divider {
    width: 100%;
    height: 1px;
    background-color: var(--walletPopupDivider);
    margin: 0px -24px;
    padding: 0px 24px;
  }

  .nostra__walletPopup__accounts {
    margin-top: 24px;
  }

  .nostra__walletPopup__account-row {
    margin-bottom: 16px;
    position: relative;

    .nostra__walletPopup__account-row-selected-indicator {
      height: 100%;
      display: flex;
      align-items: center;
      position: absolute;
      left: -24px;
    }
  }

  .nostra__walletPopup__account-actions {
    margin-top: 10px;
    margin-bottom: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.nostra__walletPopup__button {
  box-shadow: var(--downWeakShadow);
  border: 1px solid var(--buttonBorder);
  border-radius: 4px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;

  &:first-child {
    margin-right: 20px;
  }
}

.nostra__walletPopup__button-primary {
  background-color: var(--walletPopupPrimaryButton);
  box-shadow: var(--downWeakShadow);
  border-radius: 4px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;

  &[data-disabled='true'] {
    background-color: var(--buttonDisabled);
    box-shadow: none;
  }
}

.nostra__walletPopup__backdrop {
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  z-index: 2;
}
