.nostra__tablePaginationNavigateButton {
  width: 36px;
  height: 36px;
  border-radius: 36px;
  border: 1px solid #e5e7eb;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
}
