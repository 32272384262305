.nostra__tableRow[id^='market-'] {
  cursor: pointer;

  &:hover {
    background-color: #fdefe7;
  }
}

.nostra__market-table-row__cell {
  height: 100%;
  display: flex;
  align-items: center;
}

.nostra__market-table-row__market {
  .nostra__market-table-row__market-token {
    margin-left: 12px;
    display: flex;
    flex-direction: column;

    .nostra__loading-placeholder:first-child {
      margin-bottom: 12px;
    }
  }
}

.nostra__market-table-row__multiple-values {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;

  .nostra__typography {
    white-space: nowrap;
  }

  .nostra__loading-placeholder {
    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }
}
