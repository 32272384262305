.nostra__summary {
  display: flex;
}

.nostra__summary__market-overview-grid {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 2fr 1fr 2fr;
  grid-gap: 12px;

  .nostra__summary__market-overview-value {
    text-align: center;

    &:nth-child(2n) {
      grid-column: 3 / 4;
    }

    div:first-child {
      margin-bottom: 8px;
    }
  }
}
