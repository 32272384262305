.nostra__chart__date-tick-container {
  $date-tick-width: 62px;
  $date-tick-height: 17px;

  --tick-width: #{$date-tick-width};
  --tick-height: #{$date-tick-height};
  width: var(--tick-width);
  height: var(--tick-height);
}

.nostra__chart__date-tick {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  margin-right: 10px;
}

.recharts-yAxis .nostra__chart__tick-container {
  transform: translate(calc(-1 * var(--tick-width)), calc(-1 * var(--tick-height) / 2));
}

.nostra__chart__text-tick-container {
  $text-tick-width: 40px;
  $text-tick-height: 16px;

  --tick-width: #{$text-tick-width};
  --tick-height: #{$text-tick-height};
  width: var(--tick-width);
  height: var(--tick-height);
}

.nostra__chart__tooltip {
  z-index: 100;
  background-color: black;
  border-radius: 4px;
  position: relative;
  padding: 12px 16px;

  .nostra__chart__tooltip__value1 {
    display: flex;
    align-items: center;

    &::before {
      content: ' ';
      display: block;
      width: 10px;
      height: 10px;
      margin-right: 8px;
      background-color: var(--chartStroke1);
    }
  }

  .nostra__chart__tooltip__value2 {
    display: flex;
    align-items: center;

    &::before {
      content: ' ';
      display: block;
      width: 10px;
      height: 10px;
      margin-right: 8px;
      background-color: var(--chartStroke2);
    }
  }
}

.nostra__chart__balance-tooltip-content {
  width: 144px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;

  .nostra__chart__balance-tooltip-content-title {
    margin-bottom: 8px;
  }

  .nostra__chart__balance-tooltip-content-value {
    margin-bottom: 4px;
  }
}
