.nostra__tableBodyCell__account {
  max-width: 223px;

  .nostra__typography {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
  }
}

.nostra__market-table-row__cell-account {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  > div:first-child {
    margin-bottom: 2px;
  }
}

.nostra__tooltip-wrapper {
  .nostra__account-management__anchor {
    > svg > g > path {
      fill: var(--textHighlight);
    }
  }
}

.nostra__account-management__anchor {
  &:hover {
    > svg > g > path {
      fill: var(--textHighlight);
    }
  }
}

.nostra__account-management-row__selected-indicator {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
}
