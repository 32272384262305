.nostra__alpha-popup {
  width: 555px;
  padding: 24px;

  .nostra__link {
    color: var(--primaryMain);
    text-decoration: underline;
  }

  .nostra__alpha-popup-divider-large {
    margin-top: 16px;
    margin-bottom: 24px;
    width: 100%;
    height: 1px;
    background-color: #bfc0c0;
  }

  .nostra__alpha-popup-description {
    margin-bottom: 24px;

    strong {
      font-weight: 700;
    }
  }

  .nostra__alpha-popup-divider-small {
    margin-top: 16px;
    margin-bottom: 12px;
    width: 100%;
    height: 1px;
    background-color: #bfc0c0;
  }

  .nostra__alpha-popup-info-row-header {
    margin-bottom: 12px;
  }

  .nostra__alpha-popup-info-row {
    display: flex;
    align-items: center;

    .nostra__alpha-popup-asset-column {
      display: flex;
      align-items: center;
      width: 30%;

      svg {
        margin-right: 12px;
      }
    }

    .nostra__alpha-popup-asset-tier-column {
      display: flex;
      align-items: center;
      width: 50%;
    }

    .nostra__alpha-popup-limit-column {
      display: flex;
      align-items: center;
      width: 20%;
      justify-content: flex-end;
    }
  }

  .nostra__alpha-popup-row-divider {
    margin-top: 8px;
    margin-bottom: 8px;
    width: 100%;
    height: 1px;
    background-color: #f3f4f6;
  }

  .nostra__tooltip-wrapper-anchor {
    justify-content: flex-start;
  }

  .nostra__alpha-popup__terms {
    margin-top: 24px;
    display: flex;
    align-items: center;
  }

  .nostra__checkbox {
    margin-right: 8px;
  }

  .nostra__alpha-popup-action {
    margin-top: 24px;
  }
}

.nostra__alpha-popup__terms-tooltip {
  max-width: 406px;
  padding: 12px;
  display: flex;
  align-items: center;
  box-sizing: border-box;

  .nostra__icon {
    flex-shrink: 0;
    margin-right: 8px;
  }
}
