.nostra__analytics-box {
  padding: 24px;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  background: var(--analyticsSectionBackground);
  box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
  box-sizing: border-box;
  border-radius: 4px;
  margin: 0 6px;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}
