@mixin overlay {
  position: fixed;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
}

.nostra__menu__backdrop {
  @include overlay;
  z-index: 99998;
}

.nostra__menu {
  position: absolute;
  right: 0px;
  bottom: -15px;
  background-color: var(--menuBackground);
  transform: translateY(100%);
  box-shadow: 0px 20px 25px rgba(31, 41, 55, 0.1), 0px 10px 10px rgba(31, 41, 55, 0.04);
  border-radius: 8px;
  z-index: 99999;
}
