.nostra__tablePagination__button {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  margin-left: 7px;
  margin-right: 7px;

  &:first-child {
    margin-left: 0px;
  }
  &:last-child {
    margin-right: 0px;
  }

  &[data-selected='true'] {
    background-color: #bacaec;
  }
}
