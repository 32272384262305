.nostra__repay-modal__info {
  margin-bottom: 8px;
}

.nostra__repay-modal__info-large {
  margin-bottom: 32px;
}

.nostra__repay-modal__info-large {
  margin-bottom: 32px;
  z-index: 1;
  position: relative;
}
