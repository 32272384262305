.nostra__button-primary {
  background-color: var(--buttonPrimary);
  box-shadow: var(--downWeakShadow);
  border-radius: 4px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.nostra__button-primary__disabled {
  background-color: var(--buttonPrimaryDisabled);
}
