.nostra__summary-box__side-value {
  margin: 8px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:first-child {
    margin-top: 0;
  }
}
