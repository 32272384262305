.nostra__analytics__currency-switcher {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .nostra__analytics__currency-switcher__title {
    margin-right: 12px;
  }

  button {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
    border: 1px solid var(--currencySwitcherButtonBorder);
    background: var(--currencySwitcherButtonBackground);
    border-radius: 4px;
    width: 54px;
    height: 28px;
    margin-left: 4px;

    &:disabled {
      border-color: var(--currencySwitcherButtonDisabledBorder);
      background: var(--currencySwitcherButtonDisabledBackground);
    }
  }
}
