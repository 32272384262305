.nostra__withdraw-modal__input {
  margin-bottom: 32px;
}

.nostra__withdraw-modal__lending {
  margin-bottom: 8px;
}

.nostra__withdraw-modal__collateral {
  margin-bottom: 32px;
  z-index: 1;
  position: relative;
}
