$manage-button-width: 112px;

.nostra__borrow-positions {
  padding: 24px 12px 36px 12px;
  display: flex;
  flex-direction: column;

  .nostra__table {
    tbody {
      .nostra__tableRow {
        border-bottom-style: dotted;
      }
    }
  }
}

.nostra__borrow-positions__empty {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 24px;
}

.nostra__borrow-positions__header {
  padding: 0 12px 24px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .nostra__borrow-positions__header-total-debt {
    display: flex;
    align-items: center;

    :first-child {
      margin-right: 8px;
    }
  }
}

.nostra__tableRow[id^='borrow-position-'] {
  .nostra__tableBodyCell:last-child {
    width: $manage-button-width;
  }
}

.nostra__borrow-positions__asset-cell {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .nostra__logo {
    margin-right: 12px;
  }

  .nostra__token {
    flex: 1 40%;
  }

  .nostra__asset-tier {
    margin-top: 6px;
  }
}

.nostra__borrow-positions__loading-asset-cell {
  display: grid;
  grid-gap: 12px 8px;
  grid-template-areas:
    'token longer-placeholder'
    'token shorter-placeholder';
  align-items: center;

  > :first-child {
    grid-area: token;
  }

  > :nth-child(2) {
    grid-area: longer-placeholder;
  }

  > :nth-child(3) {
    grid-area: shorter-placeholder;
  }
}

.nostra__borrow-positions__multiple-values {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  white-space: nowrap;
}

.nostra__borrow-positions__action-cell {
  display: flex;
  align-items: center;
  justify-content: center;

  .nostra__actionButton {
    width: $manage-button-width;
  }
}
