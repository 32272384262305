.nostra__footer {
  padding-top: 48px;
  padding-bottom: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
}

.nostra__footer__links-container {
  display: flex;
  flex: 1;

  .nostra__footer__links-column {
    display: flex;
    flex-direction: column;
    margin-left: 40px;
    margin-right: 100px;

    &:first-child {
      margin-left: 0;
    }

    .nostra__footer__link-container {
      margin: 0 0 4px 0;
      display: flex;
      align-items: center;

      .nostra__icon {
        margin-right: 8px;
      }

      > span {
        font-family: sofia-pro, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: var(--footerLink);
      }
    }

    .nostra__footer__link-icon-right {
      .nostra__icon {
        margin-left: 8px;
      }
    }

    .nostra__footer__link {
      padding-bottom: 2px;
      display: flex;
      align-items: center;
      font-family: sofia-pro, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: var(--footerLink);
      cursor: pointer;
      text-decoration: none;
    }

    .nostra__hover-animation::after {
      background-color: var(--footerLink);
    }

    &.nostra__footer__links-column__left-padded {
      margin-left: 100px;
    }
  }

  &.right {
    justify-content: flex-end;

    & > .nostra__footer__links-column {
      align-items: flex-end;
      justify-content: center;
      margin-left: 60px;
      margin-right: 0px;

      .nostra__footer__link-container {
        width: 128px;
        margin-bottom: 4px;
        display: flex;
        justify-content: flex-end;
      }

      .nostra__icon {
        margin-right: 0;
        margin-left: 8px;
      }
    }
  }
}
