.nostra__transaction-history {
  background: var(--sectionBackground);
  border: 1px solid var(--sectionBorder);
  border-radius: 4px;
  padding: 24px;
  z-index: 1;

  > div:nth-child(2) {
    margin-bottom: 8px;
  }

  .nostra__transaction-history__header {
    height: 32px;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .nostra__transaction-history__header-back {
      display: flex;
      align-items: center;

      .nostra__icon-back {
        margin-right: 12px;
      }
    }

    .nostra__transaction-history__header-actions {
      display: flex;
      align-items: center;

      .nostra__icon-download {
        margin-right: 8px;
      }
    }
  }

  .nostra__transaction-history__search {
    margin-top: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .nostra__transaction-history__search-right {
      display: flex;
      align-items: center;

      > * {
        margin-left: 8px;
      }

      .nostra__transaction-history__search__page-num {
        margin-left: 16px;
      }

      .nostra__transaction-history__search__nav {
        display: flex;
        border-radius: 8px;
        background-color: var(--transactionHistoryNavBackground);

        .nostra__btn {
          width: 26px;
          height: 32px;
        }
      }

      .nostra__transaction-history__dropdown {
        .nostra__menu-wrapper {
          > .nostra__btn {
            position: relative;
            min-width: 160px;
            height: 36px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            background-color: var(--transactionHistoryDropdownBackground);
            border: 1px solid var(--transactionHistoryDropdownBorder);
            border-radius: 8px;
            padding: 8px;
            box-sizing: border-box;

            .nostra__transaction-history__dropdown-value {
              width: 24px;
              text-align: right;
              padding-right: 7px;
            }
          }
        }

        .nostra__menu {
          background-color: var(--transactionHistoryDropdownBackground);
          border: 1px solid var(--transactionHistoryDropdownBorder);
          border-radius: 8px;
          padding: 8px 0;
          box-sizing: border-box;
          bottom: -4px;

          .nostra__transaction-history__dropdown-item {
            padding: 0 28px 0 12px;
            height: 36px;
            width: 60px;

            .nostra__typography {
              width: 100%;
              text-align: right;
            }

            &:hover {
              background-color: var(--transactionHistoryDropdownItemHighlight);
            }
          }
        }
      }
    }
  }

  .nostra__transaction-history__table {
    margin-top: 32px;

    .nostra__icon-clipboard {
      margin-left: 8px;
    }

    .nostra__icon-right-arrow-thin {
      margin-left: 8px;
      margin-right: 8px;
    }
  }
}
