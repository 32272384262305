@use './Table.scss' as TableStyle;

.nostra__tableHeadCell {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: TableStyle.$tableColumnPadding;
  padding-left: TableStyle.$tableColumnPadding;

  &:first-child {
    padding-left: TableStyle.$tableSidePaddingLeft;
  }

  &:last-child {
    padding-right: TableStyle.$tableSidePaddingRight;
  }

  &[data-align='left'] {
    text-align: left;
  }

  &[data-align='center'] {
    text-align: center;
  }

  &[data-align='right'] {
    text-align: right;
  }
}
