$buttonHeight: 32px;

.nostra__header .nostra__walletButton__disconnected .nostra__actionButton {
  background: var(--walletButtonBackground);
  border: 1px solid var(--walletButtonBorder);
  width: auto;
  padding: 0px 16px;
  box-sizing: border-box;
  height: 32px;

  &:hover {
    > div > div,
    > div > div > div {
      color: var(--textPrimary) !important;
    }

    background: var(--walletButtonHover);
  }
}

.nostra__walletButton__connected {
  max-width: 176px;
  padding: 8px 16px;
  background-color: #f5f6ff;
  display: flex;
  z-index: 1;
  border-radius: 8px;

  &[data-wallet-popup-open='true'] {
    background-color: #ffffff;
  }

  .nostra__icon-right-sidebar,
  .nostra__icon-right-sidebar-open {
    margin-left: 8px;
  }
}

.nostra__walletButton__connected-wallet-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > .nostra__typography:first-child {
    width: 118px;
    white-space: nowrap;
    overflow: hidden;
    text-align: left;
  }
}

.nostra__walletButton__connected-wallet-health-factor {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  > :last-child {
    margin-left: 4px;
  }
}

// Wallet selector

.s-dialog {
  .s-dialog__content {
    --s-dialog-width: 340px;

    .s-card {
      > .s-card-title {
        padding: 16px;
        font-size: 16px;
        line-height: 24px;
      }

      > .s-list {
        margin-inline-start: 16px !important;
        margin-inline-end: 16px !important;
        padding: 0 0 24px 0;

        .s-list-item {
          margin-bottom: 12px !important;
          background: transparent !important;
          border: 1px solid var(--buttonBorder) !important;
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
          border-radius: 4px !important;

          .s-list-item__title {
            font-size: 16px;
            font-weight: bold;
            line-height: 24px;
            margin-bottom: 0;
          }

          img {
            width: 26px;
          }
        }
      }
    }
  }

  .material-ripple {
    display: none;
  }
}
