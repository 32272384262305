$toggle-switch-width: 32px;
$toggle-switch-height: 16px;
$toggle-switch-padding: 2px;

$thumb-size: $toggle-switch-height + 2 * $toggle-switch-padding;
$thumb-active-state-x-translation: $toggle-switch-width - $thumb-size;

$background-border-radius: calc($toggle-switch-height / 2);

.nostra__toggle-switch {
  display: flex;
  align-items: center;
}

.nostra__toggle-switch__switch-container {
  width: $toggle-switch-width;
  height: $toggle-switch-height;
  margin: $toggle-switch-padding 0;
  display: inline-flex;
  position: relative;

  input[type='checkbox'] {
    width: 100%;
    height: 100%;
    margin: 0;
    top: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    z-index: 1;
    cursor: pointer;
  }

  &.nostra__toggle-switch__switch-container-disabled,
  &.nostra__toggle-switch__switch-container-locked {
    input[type='checkbox'] {
      cursor: default;
    }
  }
}

.nostra__toggle-switch__thumb {
  width: $thumb-size;
  height: $thumb-size;
  margin-top: -$toggle-switch-padding;
  position: absolute;
  background: var(--toggleSwitchThumbInactiveBackground);
  border: 1px solid var(--toggleSwitchThumbInactiveBorder);
  border-radius: 50%;
  box-sizing: border-box;
  box-shadow: var(--downStrongShadow);
  transition: transform 300ms ease-out;

  svg {
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
  }
}

.nostra__toggle-switch__thumb-active {
  background: var(--toggleSwitchThumbActiveBackground);
  transform: translateX($thumb-active-state-x-translation);
  border-color: var(--secondaryRegular);
}

.nostra__toggle-switch__thumb-disabled {
  background: var(--toggleSwitchThumbDisabledBackground);
  border-color: var(--toggleSwitchDisabledBackground);
}

.nostra__toggle-switch__background {
  width: $toggle-switch-width;
  height: 100%;
  background: var(--toggleSwitchInactiveBackground);
  border-radius: $background-border-radius;
}

.nostra__toggle-switch__background-active {
  background: var(--secondaryRegular);
}

.nostra__toggle-switch__background-disabled {
  background: var(--toggleSwitchDisabledBackground);
}

.nostra__toggle-switch__label {
  padding-left: 6px;
}
