.nostra__analytics {
  padding: 24px 0;
  z-index: 1;

  .nostra__analytics__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  > .nostra__analytics__divider {
    margin: 16px 0 24px;
    border-bottom: 1px solid var(--sectionBorder);
  }

  > .nostra__analyticsTokenStats__container,
  > .nostra__analytics__graphs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 24px;
    row-gap: 24px;
    margin: 24px 6px;
  }
}
