tbody {
  .nostra__tableRow {
    &:first-child {
      border-top: 1px solid var(--tableTopBorder);
      border-bottom: 1px solid var(--tableBorder);
    }

    &:not(:first-child) {
      border-bottom: 1px solid var(--tableBorder);
    }

    &:nth-child(2n + 1) {
      background-color: var(--tableOddRowBackground);
    }

    &:nth-child(2n) {
      background-color: var(--tableEvenRowBackground);
    }
  }
}
