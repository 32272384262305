.nostra__health-factor-status-dot {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  margin-right: 8px;

  &[data-status='loading'] {
    background-color: var(--healthFactorLoading);
    box-shadow: 0 0 0 0 rgba(var(--healthFactorLoadingRGB), 1);
    transform: scale(1);
    animation: pulse-loading-health-factor-status-dot 2s infinite;

    @keyframes pulse-loading-health-factor-status-dot {
      0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(var(--healthFactorLoadingRGB), 0.7);
      }

      70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(var(--healthFactorLoadingRGB), 0);
      }

      100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(var(--healthFactorLoadingRGB), 0);
      }
    }
  }

  &[data-status='low'] {
    background-color: var(--healthFactorLow);
  }

  &[data-status='medium'] {
    background-color: var(--healthFactorMedium);
  }

  &[data-status='high'] {
    background-color: var(--healthFactorHigh);
  }
}
