.nostra__notification-item__outer-container {
  animation-name: fade-in-from-top;
  animation-duration: 0.5s;
  transition-property: max-height;
  transition-duration: 0.5s;

  .nostra__notification-item {
    background: var(--sectionBackground);
    border: 1px solid var(--sectionBorder);
    border-radius: 8px;
    padding: 16px;
    display: inline-block;

    .nostra__notification-item__user-avatar {
      display: inline-flex;
      align-items: center;

      canvas {
        border-radius: 50%;
        margin-right: 24px;
      }
    }

    .nostra__notification-item__detail {
      display: inline-block;

      .nostra__notification-item__detail-info {
        > * {
          display: inline;
          margin: 0 2px;

          &:first-child {
            margin-left: 0;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }

  &.dismissing {
    margin: 0;
    opacity: 0;
    border: none;
  }

  @keyframes fade-in-from-top {
    from {
      opacity: 0;
      transform: translateY(-40px);
    }

    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
