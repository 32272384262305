.nostra__markets-filter {
  padding: 8px 16px;
  display: flex;
  align-items: center;
  border: 1px solid var(--marketsFilterButtonBorder);
  border-radius: 4px;
  background-color: var(--sectionBackground);
  box-sizing: border-box;
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.08));
  z-index: 1;

  &.nostra__markets-filter-selected {
    border-color: transparent;

    &.nostra__markets-filter-shared {
      background-color: var(--assetSharedBackground);
    }

    &.nostra__markets-filter-cross {
      background-color: var(--assetCrossBackground);
    }

    &.nostra__markets-filter-isolated {
      background-color: var(--assetIsolatedBackground);
    }

    &.nostra__markets-filter-nominal {
      background-color: var(--assetNominalBackground);
    }

    .nostra__markets-filter__count {
      background-color: var(--marketsFilterCountBackground);
    }
  }

  .nostra__markets-filter__count {
    height: 20px;
    margin-left: 8px;
    padding: 2px 6px;
    display: flex;
    align-items: center;
    border: 1px solid var(--marketsFilterCountBorder);
    border-radius: 4px;
    box-sizing: border-box;
  }
}
