.nostra__modal-prompt {
  width: 554px;
  padding: 45px 48px 32px 48px;

  .nostra__modal-prompt__text {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .nostra__modal-prompt__actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 38px;
    width: 100%;
  }
}
