.nostra__breadcrumb {
  > ul {
    display: flex;
    flex-direction: row;

    > li {
      display: flex;

      &:not(:last-of-type)::after {
        content: '/';
        color: var(--breadcrumbDivider);
        margin-left: 24px;
        margin-right: 24px;
        font-size: 24px;
        line-height: 32px;
      }
    }
  }
}
