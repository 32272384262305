.nostra__graph__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: var(--analyticsSectionBackground);
  box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
  box-sizing: border-box;
  border-radius: 4px;
  padding: 32px;
}

.nostra__graph__heading {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .nostra__typography {
    display: flex;
    align-items: center;
  }

  .nostra__logo {
    margin-right: 12px;
  }
}

.nostra__graph {
  margin-top: 30px;
  min-height: 440px;
  display: flex;
  justify-content: center;
  align-items: center;
}
