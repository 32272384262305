.nostra__graph__time-resolution {
  display: flex;  
}

.nostra__graph__time-resolution__button {
  border: 1px solid var(--chartTitle);
  padding: 4px 12px;
  margin-right: 4px;
  border-radius: 4px;

  &[data-selected="true"] {
    background-color: var(--chartTitle);
  }
}