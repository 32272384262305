.nostra__analytics__market-supply {
  display: flex;

  .nostra__analytics-box__main-value {
    flex: 1;

    .nostra__loading-placeholder {
      margin-top: 8px;
    }
  }
}
