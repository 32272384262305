.nostra__loading {
  fill: none;
  stroke-width: 10px;
  stroke-linecap: square;
}

.nostra__loading__color-bg-default {
  stroke: rgba(255, 255, 255, 0.5);
}

.nostra__loading__color-bg-primary {
  stroke: rgba(122, 122, 122, 0.5);
}

.nostra__loading__color-bg-secondary {
  stroke: rgba(191, 192, 192, 0.5);
}

.nostra__loading__animate {
  transform-origin: 50px 50px 0;
  stroke-dasharray: 250;
  animation: circle-animation 4s linear infinite;
}

.nostra__loading__color-animate-default {
  stroke: rgba(255, 255, 255, 1);
}

.nostra__loading__color-animate-primary {
  stroke: rgba(122, 122, 122, 1);
}

.nostra__loading__color-animate-secondary {
  stroke: rgba(191, 192, 192, 1);
}

@keyframes circle-animation {
  0% {
    stroke-dashoffset: 40;
    transform: rotate(0deg);
  }
  50% {
    stroke-dashoffset: 150;
    transform: rotate(540deg);
  }
  100% {
    stroke-dashoffset: 40;
    transform: rotate(1080deg);
  }
}
