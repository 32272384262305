.nostra__analytics__token-selector {
  position: relative;

  .nostra__analytics__token-selector__backdrop {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 10;
    cursor: default;
  }

  .nostra__analytics__token-selector__menu {
    position: absolute;
    background: var(--breadcrumbMenuBackground);
    width: 400px;
    top: 50px;
    left: 0;
    padding: 36px 0;
    border-radius: 8px;
    z-index: 11;

    .nostra__analytics__token-selector__menu-overview {
      margin: 0 32px;
    }

    .nostra__analytics__token-selector__menu-divider {
      width: 100%;
      height: 1px;
      background-color: var(--breadcrumbMenuDivider);
      margin-top: 36px;
      margin-bottom: 24px;
    }

    .nostra__analytics__token-selector__menu-title {
      color: var(--breadcrumbMenuTitle) !important;
      margin: 0 32px 8px 32px;
    }

    ul {
      margin: 0 32px;
      overflow: hidden;

      li {
        height: 32px;

        .nostra__link {
          position: relative;

          .nostra__logo {
            position: relative;
            left: -30px;
            transition: left 0.2s ease-out;
            margin-right: 8px;
          }

          .nostra__typography {
            position: relative;
            left: -30px;
            transition: left 0.2s ease-out;
          }

          .nostra__icon-right-arrow {
            position: absolute;
            right: 0;
            opacity: 0;
            transition: opacity 0.2s ease-out;
          }

          &:hover {
            .nostra__logo {
              left: 0;
            }

            .nostra__typography {
              left: 0;
            }

            .nostra__icon-right-arrow {
              opacity: 1;
            }
          }
        }
      }
    }

    .nostra__link {
      width: 100%;
      display: flex;
      justify-content: flex-start;

      .nostra__typography {
        width: 100%;
        color: var(--breadcrumbMenuText) !important;
        text-align: start;

        &:hover {
          color: var(--breadcrumbMenuTextHighlight) !important;
        }
      }
    }
  }

  .nostra__analytics__token-selector__btn {
    > .nostra__logo {
      margin-right: 12px;
    }

    > .nostra__icon {
      margin-left: 20px;
    }
  }
}
