.nostra__positions {
  margin-top: 12px;
  display: flex;

  .nostra__positions__container {
    flex: 1;
    background: var(--sectionBackground);
    border: 1px solid var(--sectionBorder);
    border-radius: 4px;
    margin: 0 6px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
