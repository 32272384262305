.nostra__borrow-modal__balance {
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nostra__borrow-modal__input {
  margin-bottom: 32px;
}

.nostra__borrow-modal__borrowing {
  margin-bottom: 8px;
}

.nostra__borrow-modal__collateral {
  margin-bottom: 32px;
  z-index: 1;
  position: relative;
}
