.nostra__account-name-input-field__container {
  .nostra__tooltip {
    --anchorWidth: 32px;
  }

  position: relative;
  margin-left: -10px;
}

.nostra__account-name-input-field {
  width: 202px;
  height: 50px;
  background: var(--accountNameInputBackground);
  border: 1px solid var(--secondaryRegular);
  border-radius: 8px;
  box-sizing: border-box;
  position: relative;

  &.nostra__account-name-input-field__error {
    border: 1px solid var(--textError);
  }

  .nostra__account-name-input-field__value {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    .nostra__base-input {
      margin-top: -2px;
      padding: 0 8px 16px 9px;
      border: none;
      outline: none;
      background: none;
      color: inherit;
      font: inherit;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  .nostra__account-name-input-field__id {
    position: absolute;
    bottom: 4px;
    left: 9px;
  }
}

.nostra__account-name-input-field__error-tooltip {
  width: 240px;
  padding: 12px;
  display: flex;
  align-items: center;

  .nostra__icon {
    margin-right: 8px;
    flex-shrink: 0;
  }
}
