$border-size: 1px;
$border-radius: 8px;
$input-field-height: 68px;
$currency-selector-width: 100%;

.nostra__currency-input {
  width: $currency-selector-width;

  .nostra__currency-input__max-amount {
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .nostra__currency-input__max-amount-value {
    display: flex;
    align-items: center;

    div {
      margin-left: 8px;
    }
  }

  .nostra__currency-input__field-container {
    display: flex;
    height: $input-field-height;
    background-color: var(--currencyInputBackground);
    border: $border-size solid var(--currencyInputBorder);
    border-radius: $border-radius;
    box-sizing: border-box;
  }

  .nostra__currency-input__field-container-error {
    border-color: var(--currencyInputErrorBorder);
  }

  .nostra__currency-input__field-container-disabled {
    border-color: var(--currencyInputBorder);
  }

  .nostra__currency-input__field-container-focused {
    border-color: var(--currencyInputFocusedBorder);
  }

  .nostra__currency-input__input-container {
    width: 100%;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: $border-radius - $border-size;
    box-sizing: border-box;
  }

  .nostra__currency-input__input-container-disabled {
    background-color: var(--currencyInputDisabledBackground);
  }

  .nostra__currency-input__amount-container {
    width: 100%;
    min-width: 0; // forces the container to shrink if the content is too long
    margin-right: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .nostra__typography {
      overflow: hidden;
    }

    > .nostra__loading-placeholder:first-child {
      margin-bottom: 8px;
    }
  }

  .nostra__currency-input__amount {
    .nostra__base-input {
      width: 100%;
      padding: 0;
      color: inherit;
      font: inherit;
      background-color: transparent;
      border: none;

      &:focus {
        outline: none;
      }

      &::placeholder {
        opacity: 1;
        color: var(--textPrimary);
      }
    }
  }

  .nostra__currency-input__fiat-amount {
    display: flex;
  }

  .nostra__currency-input__fiat-amount-currency {
    padding-left: 4px;
  }

  .nostra__currency-input__token {
    display: flex;
    align-items: center;

    .nostra__currency-input__token-label {
      padding-right: 10px;
      align-self: center;
    }
  }

  .nostra__currency-input__error {
    margin-top: 8px;

    .nostra__link {
      text-decoration: underline;
    }
  }

  .nostra__currency-input__percentage-buttons {
    display: flex;
    justify-content: flex-end;
    padding-top: 8px;

    .nostra__currency-input__percentage-button {
      margin-left: 8px;
      width: 70px;
      background-color: var(--currencyInputBackground);
      border: 1px solid var(--currencyInputBorder);
      border-radius: 5px;
      height: 32px;
      cursor: pointer;

      &[data-disabled='true'] {
        border: 1px solid var(--currencyInputDisabledBorder);
        cursor: default;
      }
    }
  }

  .nostra__loading-button-placeholder {
    &:not(:last-child) {
      margin-right: 8px;
    }
  }
}
