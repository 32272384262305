.nostra__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  user-select: none;
  text-decoration: none;

  &:disabled {
    cursor: default;
  }
}
