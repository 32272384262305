.nostra__summary-box {
  padding: 24px;
  height: 196px;
  display: flex;
  flex: 1;
  flex-direction: column;
  background: var(--sectionBackground);
  border: 1px solid var(--sectionBorder);
  box-sizing: border-box;
  border-radius: 4px;
  margin: 0 6px;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

.nostra__summary-box__title {
  margin-bottom: 24px;
}

.nostra__summary-box__horizontal-divider {
  width: 100%;
  height: 1px;
  background-color: var(--sectionBorder);
}
