.nostra__markets-container {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  padding: 24px;
  border-radius: 4px;
  background: var(--sectionBackground);
  border: 1px solid var(--sectionBorder);
  z-index: 1;
}

.nostra__markets__header {
  margin-bottom: 36px;
}

.nostra__markets__filters {
  display: flex;
  margin-bottom: 36px;
  z-index: 2;

  .nostra__markets-filter__container,
  .nostra__loading-button-placeholder {
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }
  }

  .nostra__markets__small-balances-checkbox {
    display: flex;
    align-items: center;

    .nostra__typography {
      margin-left: 8px;
    }
  }
}

.nostra__markets__table {
  padding: 0px;
  z-index: 1;

  .nostra__tableHeadCell:last-child,
  .nostra__tableBodyCell:last-child {
    padding-right: 48px;
  }

  #market-body-global-error {
    cursor: inherit;

    .nostra__markets__table__global-error {
      height: 320px;

      .nostra__tableBodyCell__content {
        flex-direction: column;
        justify-content: center;

        > svg {
          margin: 6px 0;
        }
        > div {
          margin: 2px 0;
        }
      }
    }

    &:hover {
      background-color: inherit;
    }
  }
}
