.nostra__terms {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--sectionBackground);
  border: 1px solid var(--sectionBorder);
  border-radius: 4px;
  padding: 24px;
  z-index: 1;

  .nostra__terms__body-content {
    width: 100%;
    padding: 0 96px;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;

    @media screen and (max-width: 768px) {
      padding: 0 16px;
    }

    > p {
      margin: 10px 0 30px 20px;
    }

    .nostra__terms__link {
      color: var(--textHighlight);
    }

    .nostra__hover-animation::after {
      background-color: var(--textHighlight);
    }

    .nostra__terms__list {
      padding-left: 20px;
      margin-bottom: 10px;

      > li {
        list-style-type: disc;
        margin-top: 10px;
      }
    }
  }
}

.nostra__bold {
  font-weight: 700;
}
