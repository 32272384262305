.nostra__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 24px 0px;

  .nostra__header__logo {
    flex: 1;
    z-index: 0;
  }

  .nostra__header__content {
    display: flex;
    align-items: center;

    .nostra__header__links {
      display: flex;
      align-items: center;

      .nostra__header__link-container {
        margin-right: 40px;

        .nostra__header__link {
          padding-bottom: 2px;

          &.active {
            &.nostra__hover-animation::after {
              transform: scaleX(1);
              background-color: var(--headerLink);
            }
          }
        }

        .nostra__hover-animation::after {
          background-color: var(--headerLink);
        }
      }
    }
  }
}
