.nostra__accountManagement {
  background: var(--sectionBackground);
  border: 1px solid var(--sectionBorder);
  border-radius: 4px;
  padding: 24px;
  z-index: 1;

  .nostra__accountManagement-table {
    margin-top: 24px;

    .nostra__tableBodyCell,
    .nostra__tableHeadCell {
      width: 108px;

      &:first-child {
        width: inherit;
      }

      &:last-child {
        width: 32px;
      }
    }
  }

  .nostra__accountManagement-actions {
    margin-top: 32px;
    display: flex;
    align-items: center;
    justify-content: center;

    .nostra__accountManagement-button {
      background-color: white;
      border: 1px solid var(--buttonBorder);
      box-shadow: var(--downWeakShadow);
      border-radius: 4px;
      height: 40px;
      padding: 0px 14px;

      &:not(:last-child) {
        margin-right: 24px;
      }

      &[data-disabled='true'] {
        background-color: var(--buttonDisabled);
        box-shadow: none;
      }

      > svg {
        margin-left: 8px;
      }
    }
  }
}
