.nostra__account-menu-tooltip {
  width: 172px;

  .nostra__account-menu-tooltip__actions {
    li {
      display: flex;
      align-items: center;
      height: 40px;
      padding: 0px 16px;

      &:hover {
        background-color: #fdefe7;

        &:first-child {
          border-radius: 8px 8px 0px 0px;
        }

        &:last-child {
          border-radius: 0px 0px 8px 8px;
        }

        &[data-disabled='true'] {
          background-color: transparent;
        }
      }
    }
  }

  .nostra__account-menu-tooltip-button {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;

    &.nostra__account-menu-tooltip-button-highlight {
      > div {
        color: var(--textError) !important;
      }

      &[data-disabled='true'] > div {
        color: var(--textDisabled) !important;
      }
    }
  }
}
