$lending-collateral-locked-tooltip-width: 428px;

.nostra__deposit-modal__input {
  margin-bottom: 24px;
}

.nostra__deposit-modal__lending {
  margin-bottom: 8px;

  .nostra__deposit-modal__lending__title {
    margin-bottom: 8px;
    display: flex;
    align-items: center;

    > .nostra__typography {
      margin-left: 4px;
    }
  }

  .nostra__tooltip-wrapper-anchor {
    justify-content: flex-start;
    width: fit-content;
  }

  .nostra__tooltip {
    min-width: 428px;
  }
}

.nostra__deposit-modal__collateral {
  z-index: 1;
  position: relative;
  margin-bottom: 32px;

  .nostra__deposit-modal__collateral__title {
    margin-bottom: 8px;
    display: flex;
    align-items: center;

    > .nostra__typography {
      margin-left: 4px;
    }
  }

  .nostra__tooltip-wrapper-anchor {
    justify-content: flex-start;
    width: fit-content;
  }

  .nostra__tooltip {
    min-width: $lending-collateral-locked-tooltip-width;
  }
}

.ReactCollapse--collapse {
  transition: height 300ms;
}

.nostra__deposit-modal__info-tooltip {
  width: $lending-collateral-locked-tooltip-width;
  padding: 12px;
  box-sizing: border-box;

  .nostra__deposit-modal__info-tooltip-title {
    margin-bottom: 8px;
    display: flex;
    align-items: center;

    .nostra__icon {
      margin-right: 8px;
    }
  }

  .nostra__deposit-modal__info-tooltip-description {
    .nostra__typography {
      white-space: normal;
    }
  }

  .nostra__link {
    margin-top: 8px;
    display: block;

    div {
      text-decoration: underline;
    }
  }
}

.nostra__action-modal__info-message {
  margin-top: 32px;
  margin-bottom: 16px;

  a {
    text-decoration: underline;
  }
}
