.nostra__modal {
  width: 554px;
  padding: 24px;
}

.nostra__modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  position: relative;
  box-sizing: border-box;
}

.nostra__modal-close-wrapper {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
