.nostra__unsupported-network-popup {
  max-width: 555px;
  padding: 24px;
  box-sizing: border-box;

  .nostra__link {
    color: var(--primaryMain);
    text-decoration: none;
  }
}

.nostra__unsupported-network-popup__divider {
  margin: 16px 0 24px 0;
  border-top: 1px solid var(--sectionBorder);
}
