.nostra__info-tooltip-content {
  max-width: 406px;
  padding: 12px;
  display: flex;
  align-items: center;
  box-sizing: border-box;

  .nostra__icon {
    flex-shrink: 0;
    margin-right: 8px;
  }
}
