.nostra__asset-tier {
  height: 18px;
  padding: 2px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9px;
  box-sizing: border-box;
  text-align: center;
  text-transform: capitalize;

  &.nominal {
    background-color: var(--assetNominalBackground);
  }

  &.isolated {
    background-color: var(--assetIsolatedBackground);
  }

  &.cross {
    background-color: var(--assetCrossBackground);
  }

  &.shared {
    background-color: var(--assetSharedBackground);
  }

  div {
    line-height: 1;
  }
}
