.nostra__search-input {
  position: relative;
  width: 270px;
  height: 36px;
  display: flex;
  align-items: center;
  border: 1px solid var(--searchInputBorder);
  border-radius: 8px;
  box-sizing: border-box;

  &:hover {
    .nostra__icon-x-solid {
      z-index: 9999;
    }
  }

  .nostra__icon-search {
    position: absolute;
    left: 8px;
  }

  .nostra__icon-x-solid {
    position: absolute;
    right: 8px;
    z-index: -1;
  }

  .nostra__base-input {
    width: 252px;
    height: 18px;
    padding: 8px;
    padding-left: 32px;
    border: none;
    outline: none;
    border-radius: 8px;
    font: inherit;
  }
}
