@use 'sass:map';
@use 'sass:math';

$arrowWidth: 16px;
$arrow-path: (
  'left': '70% 50%, 0% 0%, 00% 100%',
  'right': '30% 50%, 100% 100%, 100% 0%',
  'bottom': '50% 30%, 0% 100%, 100% 100%',
);

@mixin arrow-clip-path($position) {
  $path: #{map.get($arrow-path, $position)};
  -webkit-clip-path: polygon($path);
  clip-path: polygon($path);
}

.nostra__tooltip {
  min-width: 100%;
  position: absolute;
  border-radius: 8px;
  box-shadow: var(--downWeakShadow);
  border: 1px solid var(--tooltipBorder);
  background: var(--tooltipDefaultBackground);
  z-index: 9998;

  &.nostra__tooltip__error {
    background: var(--tooltipErrorBackground);

    &::after {
      background: var(--tooltipErrorBackground);
    }
  }

  // the CSS variable are here for React components to override
  --anchorWidth: 16px;

  &::before {
    content: '';
    position: absolute;
    width: $arrowWidth;
    height: $arrowWidth;
    background: var(--tooltipBorder);
  }

  &::after {
    content: '';
    position: absolute;
    width: calc($arrowWidth - 2px);
    height: calc($arrowWidth - 2px);
    background: var(--tooltipDefaultBackground);
  }

  @each $horizontal-orientation in ('left', 'right') {
    $opposite-horizontal-orientation: if($horizontal-orientation == 'left', 'right', 'left');

    &.nostra__tooltip__#{$horizontal-orientation} {
      $sign: if($horizontal-orientation == 'left', -1, 1);

      display: flex;
      flex-direction: column;
      justify-content: center;
      top: 50%;
      #{$horizontal-orientation}: 0;
      transform: translate(calc($sign * (100% + $arrowWidth)), -50%);

      &::before {
        @include arrow-clip-path($horizontal-orientation);
        #{$horizontal-orientation}: 100%;
      }

      &::after {
        @include arrow-clip-path($horizontal-orientation);
        #{$horizontal-orientation}: 100%;
      }
    }

    &.nostra__tooltip__bottom-#{$horizontal-orientation} {
      transform: translateY(calc(100% + $arrowWidth));
      #{$opposite-horizontal-orientation}: 0px;

      &::before {
        #{$horizontal-orientation}: calc(100% - $arrowWidth/2 - max(var(--anchorWidth) / 2, $arrowWidth/2));
      }

      &::after {
        #{$horizontal-orientation}: calc(100% - $arrowWidth/2 - max(var(--anchorWidth) / 2, $arrowWidth/2) + 1px);
      }
    }
  }

  // Bottom tooltips

  &.nostra__tooltip__bottom-left,
  &.nostra__tooltip__bottom-right,
  &.nostra__tooltip__bottom-center {
    bottom: 0;

    &::before {
      @include arrow-clip-path('bottom');
      bottom: 100%;
    }

    &::after {
      @include arrow-clip-path('bottom');
      bottom: 100%;
    }
  }

  &.nostra__tooltip__bottom-center {
    display: flex;
    justify-content: center;
    transform: translate(-50%, calc(100% + $arrowWidth));
    left: 50%;
  }
}
