$manage-button-width: 112px;

.nostra__deposit-positions {
  padding: 24px 12px 36px 12px;

  .nostra__table {
    tbody {
      .nostra__tableRow {
        border-bottom-style: dotted;
      }
    }
  }
}

.nostra__deposit-positions__header {
  padding: 0 12px 24px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .nostra__deposit-positions__header-total-assets {
    display: flex;
    align-items: center;

    :first-child {
      margin-right: 8px;
    }
  }
}

.nostra__deposit-positions__empty {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 24px;
}

.nostra__tableRow[id^='deposit-position-'] {
  .nostra__tableBodyCell:last-child {
    width: $manage-button-width;
  }
}

.nostra__deposit-positions__asset-cell {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .nostra__logo {
    margin-right: 12px;
  }

  .nostra__token {
    flex: 1 40%;
  }

  .nostra__asset-tier {
    margin-top: 6px;
  }
}

.nostra__deposit-positions__loading-asset-cell {
  display: grid;
  grid-gap: 12px 8px;
  grid-template-areas:
    'token longer-placeholder'
    'token shorter-placeholder';
  align-items: center;

  > :first-child {
    grid-area: token;
  }

  > :nth-child(2) {
    grid-area: longer-placeholder;
  }

  > :nth-child(3) {
    grid-area: shorter-placeholder;
  }
}

.nostra__deposit-positions__multiple-values {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  white-space: nowrap;

  .nostra__loading-placeholder {
    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }
}

.nostra__deposit-positions__asset-tier {
  width: 54px;
  height: 18px;
  padding: 2px 8px;
  border-radius: 24px;
  text-align: center;
  text-transform: capitalize;

  &.isolated {
    background-color: var(--isolatedPillBackground);
  }

  &.collateral {
    background-color: var(--nominalPillBackground);
  }
}

.nostra__deposit-positions__action-cell {
  display: flex;
  align-items: center;
  justify-content: center;

  .nostra__actionButton {
    width: $manage-button-width;
  }
}
