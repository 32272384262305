@use './Table.scss' as TableStyle;

$tableRowBorderSize: 1px;

.nostra__tableBodyCell {
  height: TableStyle.$tableRowHeight;
  padding-right: TableStyle.$tableColumnPadding;
  padding-left: TableStyle.$tableColumnPadding;
  vertical-align: top;
  position: relative;

  &:first-child {
    padding-left: TableStyle.$tableSidePaddingLeft;
  }

  &:last-child {
    padding-right: TableStyle.$tableSidePaddingRight;
  }

  &[data-border='left'] {
    border-left: 1px solid var(--accountOverviewSeparator);
  }

  &[data-border='right'] {
    border-right: 1px solid var(--accountOverviewSeparator);
  }

  &[data-border='none'] {
    border: none;
  }

  &[data-align='left'] .nostra__tableBodyCell__content {
    justify-content: flex-start;
  }

  &[data-align='center'] .nostra__tableBodyCell__content {
    justify-content: center;
  }

  &[data-align='right'] .nostra__tableBodyCell__content {
    justify-content: flex-end;
  }

  .nostra__tableBodyCell__content {
    height: 100%;
    display: flex;
    align-items: center;
  }
}
