.nostra__button {
  background-color: var(--buttonDefault);
  border: 1px solid var(--buttonBorder);
  box-shadow: var(--downWeakShadow);
  border-radius: 4px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;

  &:first-child {
    margin-right: 20px;
  }
}
