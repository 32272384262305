.nostra__summary__market-supply {
  display: flex;

  .nostra__summary-box__main-value {
    flex: 1;

    .nostra__loading-placeholder {
      margin-top: 8px;
    }
  }

  .nostra__summary__market-supply-side-values {
    display: flex;
    flex: 1;
    flex-direction: column;

    .nostra__summary-box__side-value {
      width: 100%;
    }
  }
}

.nostra__summary__market-supply-tooltip {
  width: 263px;
  padding: 12px 0;
  box-sizing: border-box;

  .nostra__summary__market-supply-tooltip__title {
    margin: 0 12px 8px 12px;
    display: flex;
    align-items: center;

    .nostra__icon {
      margin-right: 8px;
    }
  }

  .nostra__summary__market-supply-tooltip__description {
    background: var(--tooltipSecondaryBackground);
    padding: 6px 12px;
    display: flex;
    align-items: center;

    .nostra__icon {
      margin-right: 8px;
    }
  }

  .nostra__summary__market-supply-tooltip__chart {
    height: 28px;
    padding: 6px 12px;

    .nostra__summary__market-supply-tooltip__chart-section {
      display: inline-block;
      height: 28px;

      &.collateral-lending {
        background-color: var(--assetCollateralLendingBackground);
      }

      &.collateral {
        background-color: var(--assetCollateralBackground);
      }

      &.lending {
        background-color: var(--assetLendingBackground);
      }

      &.idle {
        background-color: var(--assetIdleBackground);
      }
    }

    .nostra__loading-placeholder__bar {
      width: 100%;
    }
  }

  .nostra__summary__market-supply-tooltip__assets {
    .nostra__summary__market-supply-tooltip__asset {
      padding: 6px 12px;
      display: flex;
      align-items: center;

      .nostra__typography:not(:last-child) {
        flex: 1;
      }

      &::before {
        content: '';
        width: 12px;
        height: 12px;
        border-radius: 50%;
        margin-right: 6px;
      }

      &.collateral-lending::before {
        background-color: var(--assetCollateralLendingBackground);
      }

      &.collateral::before {
        background-color: var(--assetCollateralBackground);
      }

      &.lending::before {
        background-color: var(--assetLendingBackground);
      }

      &.idle::before {
        background-color: var(--assetIdleBackground);
      }
    }
  }

  .nostra__summary__market-supply-tooltip__total {
    padding: 6px 12px;
    display: flex;
    flex-wrap: wrap;

    .nostra__typography:not(:last-child) {
      flex: 1;
    }

    &::before {
      content: '';
      width: 100%;
      height: 1px;
      background-color: var(--tooltipBorder);
      margin-bottom: 8px;
    }
  }

  .nostra__loading-placeholder {
    align-items: center;
    height: 100%;
  }
}
