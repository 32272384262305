.nostra__health-factor {
  display: flex;
  align-items: center;
  justify-content: center;

  .nostra__health-factor__indicator {
    width: 8px;
    height: 8px;
    border-radius: 8px;
    margin-right: 8px;

    &[data-status='low'] {
      background-color: var(--healthFactorLow);
    }

    &[data-status='medium'] {
      background-color: var(--healthFactorMedium);
    }

    &[data-status='high'] {
      background-color: var(--healthFactorHigh);
    }
  }
}
