.nostra__mobile-banner-container {
  position: relative;
  height: 100vh;

  .nostra__background-container {
    position: absolute;
    left: 0px;
    right: 0px;
    height: 100%;
    width: 100%;
    overflow: hidden;
    z-index: -1;

    .nostra__background {
      position: absolute;
      top: -250px;
      right: -1000px;
      width: 2000px;
      z-index: -1;
    }

    .nostra__background-overlay {
      top: 0px;
      height: 2000px;
      z-index: -1;
    }
  }

  .nostra__mobile-banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 8px 0px 8px;
    z-index: 2;

    .nostra__mobile-banner__text-container {
      background-color: #f5f6ff;
      border-radius: 4px;
      padding: 32px 12px 24px 12px;
      margin-top: 24px;

      .nostra__typography {
        text-align: center;
      }

      .nostra__mobile-banner__action {
        margin-top: 24px;
      }
    }
  }
}
