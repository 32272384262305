.nostra__action-modal__separator {
  width: 100%;
  height: 1px;
  background-color: var(--actionModalSeparator);
  margin: 32px 0px;
}

.nostra__action-modal__title {
  display: flex;
  align-items: center;

  svg {
    margin: 0 4px;
  }
}

.nostra__action-modal__title-placeholder {
  display: flex;
  align-items: center;

  > .nostra__loading-placeholder:first-child {
    margin-right: 12px;
  }

  .nostra__action-modal__title-placeholder-info {
    > .nostra__loading-placeholder:first-child {
      margin-bottom: 12px;
    }
  }
}

.nostra__action-modal__title-limit {
  margin-top: 8px;
}

.nostra__action-modal__tabs {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 36px;
  column-gap: 8px;
  margin-bottom: 32px;

  .nostra__action-modal__tooltip-wrapper {
    width: 100%;
    height: 100%;
  }

  .nostra__action-modal__tab {
    width: 100%;
    height: 100%;
    border: 1px solid var(--actionModalTabEnabled);
    border-radius: 4px;

    &[data-selected='true'] {
      border: 1px solid var(--actionModalTabSelected);
    }

    &[data-disabled='true'] {
      border: 1px solid var(--actionModalTabDisabled);
    }
  }
}

.nostra__action-modal__data-container {
  display: flex;
  flex-direction: column;
  border: 1px solid #bfc0c0;
  border-radius: 8px;
  background-color: #f3f7f9;
  padding: 16px;

  .nostra__action-modal__data-row {
    padding-bottom: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 16px;

    &:last-child {
      padding-bottom: 0px;
    }
  }

  .nostra__action-modal__separator {
    margin: 0 0 12px 0;
    background-color: var(--actionModalSeparatorLight);
  }

  .nostra__action-modal__data-token-label {
    display: flex;
    align-items: center;

    svg {
      margin-right: 8px;
    }
  }
}

.nostra__action-modal__health-factor-label {
  display: flex;
  align-items: center;
  overflow: visible;

  svg {
    margin-right: 8px;
  }
}

.nostra__action-modal__health-factor {
  display: flex;
  align-items: center;

  .nostra__icon-right-arrow-thin {
    margin: 0 8px;
  }

  .nostra__tooltip-wrapper-anchor {
    justify-content: inherit;
    width: inherit;
  }
}
