.nostra__date-range-picker-input {
  height: 36px;
  display: flex;
  align-items: center;

  .nostra__date-range-picker-input__input {
    position: relative;
    width: 140px;
    display: flex;
    align-items: center;
    border: 1px solid var(--dateRangePickerBorder);
    box-sizing: border-box;
    z-index: 2;

    input {
      width: 122px;
      height: 18px;
      padding: 8px;
      border: none;
      outline: none;
      font: inherit;
    }

    button {
      position: absolute;
      right: 8px;
    }

    &.open {
      border-color: var(--dateRangePickerBorderHighlight);
    }
  }

  .nostra__menu-wrapper {
    &:first-of-type {
      .nostra__date-range-picker-input__input {
        border-radius: 8px 0 0 8px;

        input {
          border-radius: 8px 0 0 8px;
        }
      }
    }

    &:last-of-type {
      .nostra__date-range-picker-input__input {
        border-radius: 0 8px 8px 0;

        input {
          border-radius: 0 8px 8px 0;
        }
      }
    }
  }

  .nostra__date-range-picker-input__arrow {
    width: 36px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--dateRangePickerArrowBackground);
    border-top: 1px solid var(--dateRangePickerBorder);
    border-bottom: 1px solid var(--dateRangePickerBorder);
    box-sizing: border-box;
  }

  .nostra__date-range-picker-input__panel {
    .rdp {
      --rdp-cell-size: 28px;

      .rdp-caption {
        padding: 8px;

        .rdp-caption__bth-container {
          display: flex;
        }
      }

      .rdp-head {
        background-color: var(--sectionBackground);

        .rdp-head_cell {
          color: var(--textLight);
        }
      }

      .rdp-cell {
        padding: 2px;
        box-sizing: border-box;

        button.rdp-button.rdp-day {
          width: calc(var(--rdp-cell-size) - 4px);
          height: calc(var(--rdp-cell-size) - 4px);
        }

        .rdp-day_today {
          font-weight: 400;
        }

        .rdp-day_selected {
          border-radius: 4px;
          background-color: var(--dateRangePickerBorderHighlight);
        }

        .rdp-day_outside {
          opacity: 1;
          color: var(--textLight);
        }
      }

      .rdp-tfoot {
        .nostra__btn {
          width: 100%;
          margin-top: 12px;
          padding: 4px;
          border-radius: 4px;
          border: 1px solid var(--buttonBorder);
          box-shadow: var(--downWeakShadow);
        }
      }
    }
  }

  .nostra__menu {
    left: 0;
    right: initial;
    bottom: -2px;
    padding: 8px;
  }
}
