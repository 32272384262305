.nostra__dropdown__container {
  position: relative;

  .nostra__dropdown {
    display: flex;
    border-radius: 4px;
    box-shadow: var(--downWeakShadow);
    background: white;
    height: 40px;
    box-sizing: border-box;
    padding: 4px 12px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    min-width: 157px;
    cursor: pointer;

    .nostra__dropdown__value {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .nostra__dropdown__popup {
    position: absolute;
    top: 44px;
    left: 0px;
    right: 0px;
    padding: 12px;
    box-sizing: border-box;
    background-color: white;
    border-radius: 4px;
    max-height: 200px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 14px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      border: 4px solid transparent;
      background-clip: padding-box;
      border-radius: 7px;
      background-color: #f3f7f9;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      border: 5px solid transparent;
      background-clip: padding-box;
      border-radius: 7px;
      background-color: #bfc0c0;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      border: 5px solid transparent;
      background-clip: padding-box;
      border-radius: 7px;
      background-color: #a1a1a1;
    }

    .nostra__dropdown__item {
      cursor: pointer;
      padding-bottom: 16px;
      height: 16px;

      &:last-child {
        padding-bottom: 0px;
      }
    }
  }
}
