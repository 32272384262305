.nostra__markets-asset-contracts-tooltip {
  width: 180px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  cursor: default;

  .nostra__markets-asset-contracts-tooltip__header {
    margin-bottom: 8px;
    display: flex;
    align-items: center;

    > .nostra__icon {
      margin-right: 8px;
    }
  }

  .nostra__markets-asset-contracts-tooltip__contracts {
    display: grid;
    grid-template-columns: repeat(3, auto);
    gap: 8px;
  }
}
