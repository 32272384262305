@mixin overlay {
  position: fixed;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
}

.nostra__modal-wrapper__backdrop {
  @include overlay;
  background-color: var(--modalBackdrop);
  z-index: 99998;
}

.nostra__modal-wrapper__container {
  @include overlay;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  z-index: 99999;
}

.nostra__modal-wrapper {
  position: relative;
  pointer-events: all;
  background-color: var(--modalBackground);
  border-radius: 4px;
}
