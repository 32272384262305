.nostra__health-factor-tooltip {
  width: 264px;
  padding: 12px 0;
  box-sizing: border-box;

  > * {
    padding: 0 12px;
  }

  > div:not(:last-child) {
    margin-bottom: 12px;
  }

  .nostra__health-factor-tooltip__title {
    display: flex;
    align-items: center;
    height: 24px;

    svg {
      margin-right: 8px;
    }
  }

  .nostra__health-factor-tooltip__desc {
    background: var(--tooltipSecondaryBackground);
    padding: 6px 12px;
  }

  .nostra__health-factor-tooltip__example {
    li:not(:last-child) {
      margin-bottom: 12px;
    }

    li {
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: 12px;
      }

      &::before {
        content: ' ';
        background: var(--tooltipDefaultBackground);
        border-radius: 50%;
        display: block;
        width: 8px;
        height: 8px;
        margin-right: 12px;
        flex-shrink: 0;
      }
    }

    .nostra__health-factor-tooltip__example__high::before {
      background: var(--healthFactorHigh);
    }

    .nostra__health-factor-tooltip__example__medium::before {
      background: var(--healthFactorMedium);
    }

    .nostra__health-factor-tooltip__example__low::before {
      background: var(--healthFactorLow);
    }
  }
}
