$lending-collateral-locked-tooltip-width: 428px;

.nostra__adjust-modal__lending {
  margin-bottom: 8px;

  .nostra__adjust-modal__lending__title {
    margin-bottom: 8px;
  }

  .nostra__tooltip-wrapper-anchor {
    justify-content: flex-start;
    width: fit-content;
  }

  .nostra__tooltip {
    min-width: 428px;
  }
}

.nostra__adjust-modal__collateral {
  z-index: 1;
  position: relative;
  margin-bottom: 32px;

  .nostra__adjust-modal__collateral__title {
    margin-bottom: 8px;
  }

  .nostra__tooltip-wrapper-anchor {
    justify-content: flex-start;
    width: fit-content;
  }

  .nostra__tooltip {
    min-width: $lending-collateral-locked-tooltip-width;
  }

  .nostra__action-modal__adjust-error {
    margin-top: 8px;
  }
}

.ReactCollapse--collapse {
  transition: height 300ms;
}
