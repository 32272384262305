@import './reset';

body {
  background: #0a1326;
  overflow-x: hidden;
}

// Prevent page scroll when modal is open
body.modal-open {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

.nostra__hover-animation {
  position: relative;
  display: inline-block;

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: inherit;
    transform-origin: bottom left;
    transition: all 0.3s ease-out;
  }

  &:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
}

.nostra__app__root {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
  max-width: 1366px;
  margin: 0 auto;

  @media screen and (max-width: 1382px) {
    padding: 0px 16px;
  }

  .nostra__background-container {
    position: absolute;
    left: 0px;
    right: 0px;
    height: 100%;
    width: 100%;
    overflow: hidden;

    .nostra__background {
      position: absolute;
      left: 0px;
      right: 0px;
      top: -300px;
      width: 100%;
      z-index: -1;
    }

    .nostra__background-overlay {
      top: 0px;
      height: 2000px;
      z-index: -1;
      background-color: #0a1326;
      mask-image: linear-gradient(
        0deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 1) 74%,
        rgba(255, 255, 255, 0) 85%,
        rgba(255, 255, 255, 0) 100%
      );
      mask-mode: alpha;
    }
  }
}
