.nostra__tablePagination {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  margin-top: 24px;

  .nostra__tablePagination__separator {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 7px;
    margin-right: 7px;
  }
}
