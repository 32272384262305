.nostra__button-secondary {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  border-radius: 4px;
  box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
  border: 1px solid #e5e7eb;
  background: white;
  padding: 0px 8px;
}
