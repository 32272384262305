.nostra__modal-info {
  width: 534px;
  padding: 36px 48px 32px 48px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .nostra__modal-info__icon {
    margin-bottom: 16px;
  }

  .nostra__modal-info__text {
    text-align: center;
    margin-bottom: 24px;
  }

  .nostra__modal-info__actions {
    width: 207px;
  }
}
