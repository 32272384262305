// == Base style ==

$padding: 8px;

.nostra__actionButton {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: $padding;
  border-radius: 4px;
  box-sizing: border-box;
  mix-blend-mode: normal;
  width: 240px;
}

.nostra__actionButton-status-content {
  position: absolute;
  left: $padding;
}

.nostra__actionButton-status-large-content {
  min-width: 20px;
  display: flex;
  align-items: center;
}

.nostra__actionButton-status-small-content {
  min-width: 12px;
}

.nostra__actionButton-status-label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

// == Border ==

// -- Primary Large
.nostra__actionButton-border-primary-large-unfocused,
.nostra__actionButton-border-primary-large-unfocused-disabled,
.nostra__actionButton-border-primary-large-unfocused-loading {
  border: none;
}
.nostra__actionButton-border-primary-large-unfocused-success {
  border: 1px solid rgba(var(--buttonBorderSuccessRGB), 0.6);
}

// -- Primary Small
.nostra__actionButton-border-primary-small-unfocused,
.nostra__actionButton-border-primary-small-unfocused-disabled,
.nostra__actionButton-border-primary-small-unfocused-loading {
  border: none;
}
.nostra__actionButton-border-primary-small-unfocused-success {
  border: 1px solid var(--buttonBorderSuccess);
}

// -- Secondary Large
.nostra__actionButton-border-secondary-large-unfocused,
.nostra__actionButton-border-secondary-large-unfocused-loading {
  border: 1px solid var(--buttonBorder);
}
.nostra__actionButton-border-secondary-large-unfocused-disabled {
  border: 1px solid var(--buttonDisabled);
}
.nostra__actionButton-border-secondary-large-unfocused-success {
  border: 1px solid rgba(var(--buttonBorderSuccessRGB), 0.6);
}

// -- Secondary Small
.nostra__actionButton-border-secondary-small-unfocused,
.nostra__actionButton-border-secondary-small-unfocused-loading {
  border: 1px solid var(--buttonBorder);
}
.nostra__actionButton-border-secondary-small-unfocused-disabled {
  border: 1px solid var(--buttonDisabled);
}
.nostra__actionButton-border-secondary-small-unfocused-success {
  border: 1px solid var(--buttonBorderSuccess);
}

// -- Tertiary Large
.nostra__actionButton-border-tertiary-large-unfocused,
.nostra__actionButton-border-tertiary-large-unfocused-disabled,
.nostra__actionButton-border-tertiary-large-unfocused-loading {
  border: none;
}
.nostra__actionButton-border-tertiary-large-unfocused-success {
  border: 1px solid rgba(var(--buttonBorderSuccessRGB), 0.6);
}

// -- Tertiary Small
.nostra__actionButton-border-tertiary-small-unfocused,
.nostra__actionButton-border-tertiary-small-unfocused-disabled,
.nostra__actionButton-border-tertiary-small-unfocused-loading {
  border: none;
}
.nostra__actionButton-border-tertiary-small-unfocused-success {
  border: 1px solid var(--buttonBorderSuccess);
}

// -- Tertiary Small
.nostra__actionButton-border-tertiary-small-focused {
  border: 1px solid var(--secondaryRegular);
}
.nostra__actionButton-border-tertiary-small-focused-disabled,
.nostra__actionButton-border-tertiary-small-focused-loading {
  border: none;
}
.nostra__actionButton-border-tertiary-small-focused-success {
  border: 1px solid var(--buttonBorderSuccess);
}

// == Background Color ==

// -- Primary Large
.nostra__actionButton-background-primary-large {
  background: var(--secondaryRegular);
}
.nostra__actionButton-background-primary-large-disabled {
  background: var(--buttonDisabled);
}
.nostra__actionButton-background-primary-large-loading {
  background: rgba(var(--secondaryRegularRGB), 0.6);
}
.nostra__actionButton-background-primary-large-success {
  background: rgba(var(--buttonSuccessRGB), 0.6);
}

// -- Primary Small
.nostra__actionButton-background-primary-small {
  background: var(--primaryMain);
}
.nostra__actionButton-background-primary-small-disabled {
  background: var(--buttonDisabled);
}
.nostra__actionButton-background-primary-small-loading {
  background: var(--primaryLight);
}
.nostra__actionButton-background-primary-small-success {
  background: var(--buttonSuccess);
}

// -- Secondary Large
.nostra__actionButton-background-secondary-large {
  background: var(--buttonDefault);
}
.nostra__actionButton-background-secondary-large-disabled {
  background: var(--buttonSecondaryDisabled);
}
.nostra__actionButton-background-secondary-large-loading {
  background: var(--buttonSecondaryLoading);
}
.nostra__actionButton-background-secondary-large-success {
  background: rgba(var(--buttonSuccessRGB), 0.6);
}

// -- Secondary Small
.nostra__actionButton-background-secondary-small {
  background: var(--buttonDefault);
}
.nostra__actionButton-background-secondary-small-disabled {
  background: var(--buttonSecondaryDisabled);
}
.nostra__actionButton-background-secondary-small-loading {
  background: var(--buttonSecondaryLoading);
}
.nostra__actionButton-background-secondary-small-success {
  background: var(--buttonSuccess);
}

// -- Tertiary Large
.nostra__actionButton-background-tertiary-large,
.nostra__actionButton-background-tertiary-large-disabled,
.nostra__actionButton-background-tertiary-large-loading {
  background: var(--buttonTertiaryDefault);
}
.nostra__actionButton-background-tertiary-large-success {
  background: var(--buttonSuccess);
}

// -- Tertiary Small
.nostra__actionButton-background-tertiary-small,
.nostra__actionButton-background-tertiary-small-disabled,
.nostra__actionButton-background-tertiary-small-loading {
  background: var(--buttonTertiaryDefault);
}
.nostra__actionButton-background-tertiary-small-success {
  background: var(--buttonSuccess);
}

// == Shadow ==
.nostra__actionButton-shadow-default {
  box-shadow: var(--downWeakShadow);
}

.nostra__actionButton-shadow-disabled,
.nostra__actionButton-shadow-loading,
.nostra__actionButton-shadow-success {
  box-shadow: none;
}

// == Cursor ==
.nostra__actionButton-cursor-default {
  cursor: pointer;
}

.nostra__actionButton-cursor-disabled,
.nostra__actionButton-cursor-loading,
.nostra__actionButton-cursor-success {
  cursor: default;
}

// == Size ==
.nostra__actionButton-size-small {
  height: 32px;
}
.nostra__actionButton-size-large {
  height: 40px;
}

// == Width ==
.nostra__actionButton-width-full {
  width: 100%;
}

// == Hover ==

// -- Primary Large
.nostra__actionButton-hover-primary-large {
  &:hover {
    background: var(--secondaryLight);
    box-shadow: var(--downStrongShadow);
    cursor: pointer;
  }
}

// -- Primary Small
.nostra__actionButton-hover-primary-small {
  &:hover {
    background: var(--primaryLight);
    box-shadow: var(--downStrongShadow);
    cursor: pointer;
  }
}

// -- Secondary Large/Small
.nostra__actionButton-hover-secondary-large,
.nostra__actionButton-hover-secondary-small {
  &:hover {
    background: var(--buttonSecondaryHighlight);
    box-shadow: var(--downStrongShadow);
    cursor: pointer;
  }
}

// Tertiary Large/Small
.nostra__actionButton-hover-tertiary-large,
.nostra__actionButton-hover-tertiary-small {
  &:hover {
    box-shadow: var(--downStrongShadow);
    cursor: pointer;
  }
}
