.nostra__asset-tier-tooltip {
  width: 428px;
  padding: 12px;
  box-sizing: border-box;

  .nostra__asset-tier-tooltip__title {
    margin-bottom: 8px;
    display: flex;
    align-items: center;

    .nostra__icon {
      margin-right: 8px;
    }
  }

  .nostra__asset-tier-tooltip__description {
    margin-bottom: 6px;
    display: flex;
    align-items: center;

    .nostra__icon {
      margin-right: 8px;
    }
  }

  .nostra__asset-tier-tooltip__link {
    margin-top: 12px;

    .nostra__link {
      text-decoration: underline;
    }
  }

  .nostra__typography {
    text-align: left;
  }
}
